<template>
  <div>
    <v-card>
      <v-card-title>Contact Us</v-card-title>
      <v-card-text>
        You can find us 7 days a week at Caracasbaaiweg 447 (Caracasbay peninsula).
        <router-link to="/location">Location on map</router-link>.<br>
        Our opening times are from 10am to 5:30pm.<br>
        Use the form below or call us directly at <a href="tel://+5999-7380883">+5999-7380883</a>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Web form</v-card-title>
      <v-card-text>
        <v-form ref='form1'>
          <v-text-field v-model="formData.name" label="Name (required)" :rules="[stdRules.required]"></v-text-field>
          <v-text-field v-model="formData.email" label="Email address (required)" :rules="[stdRules.required]"></v-text-field>
          <v-text-field v-model="formData.mobile" label="Mobile number"></v-text-field>
          <v-textarea v-model="formData.message" label="Message" :rules="[stdRules.required]"></v-textarea>
        </v-form>
      <vue-recaptcha
        sitekey="6Le5jN0ZAAAAALClYDGiK2RiMB0lGx448klPXnLq"
        ref="invisibleRecaptcha"
        @verify="onVerify"
        @expired="onExpired"
        size="invisible"
        data-badge="inline"
      ></vue-recaptcha>        
      </v-card-text>
      <v-card-actions>
        <v-btn @click='processForm()' color="primary">
          Send
          <v-icon>mdi-mail</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
      <v-dialog v-model="showDialog" width="50%">
        <v-card>
          <v-card-title>Thank you for your message</v-card-title>
          <v-card-text>We will respond as soon as possible!</v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" fullscreen>
        <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
          <v-layout justify-center align-center>
            <v-progress-circular
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>
      <v-dialog v-model="errorDialog" width="50%">
        <v-card>
          <v-card-title class='red lighten-1'>
            <v-icon
                large
                left
              >
                mdi-alert
              </v-icon>
            Error</v-card-title>
          <v-card-text>{{error}}<br />
Please try again later or use one of the other communication options
<FooterIcons/>
          </v-card-text>
        </v-card>
      </v-dialog>       
  </div>
</template>


<script>
import GlobalHelper from "@/mixins/GlobalHelper"
import FooterIcons from "@/components/FooterIcons"
import VueRecaptcha from 'vue-recaptcha'

export default {
  mixins:[GlobalHelper],
  components:{
    FooterIcons,VueRecaptcha
  },
  data: () => ({
    formData:{},    
    showDialog:false,  
    errorDialog:false,
    error:"",
    loading:false,
  }),
  methods:{
    processForm(){
      if(!this.$refs.form1.validate()){
        //this.errorDialog = true;
        return;
      }
      this.loading = true;
      this.$refs.invisibleRecaptcha.reset()
      this.$refs.invisibleRecaptcha.execute()
    },
    onVerify: function (response) {
      this.sendForm(response)
    },
    onExpired: function () {
      this.loading = false;
      //this.$refs.invisibleRecaptcha.reset()
    },
    resetRecaptcha () {
      this.$refs.invisibleRecaptcha.reset() // Direct call reset method
    },   
    sendForm(recaptcha){
      let data = this.formData;
      let target="FOIL"
      this.sendToServer({action:'contactform',...data,recaptcha,target}).then(()=>{
        this.$refs.form1.reset()
        this.$refs.form1.resetValidation()
        this.showDialog = true
      }).catch((e)=>{
       this.error = e
       this.errorDialog = true
      }).finally(()=>{
        this.loading=false
      })
      
    }       
  }
}
</script>
